body {
  font-family: 'Maven Pro', sans-serif;
  padding: 20px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.product, .deposit, .seller-input {
  border: 1px solid black;
  padding: 20px;
  width: 100%;
  box-shadow: 2px 2px 5px #aaa;
  margin: 10px 0;
}
.product h4, .deposit h4 {
  text-align: center;
  margin-bottom: 20px;
}
button {
  display: block;
  margin: 10px auto;
  padding: 5px 15px;
  outline: none;
  border: 2px solid #000;
}
.logs {
  margin-top: 50px;
  border: 1px solid #000;
  padding: 20px;
}
.logs h4 {
  text-align: center;
  margin-bottom: 20px;
}
label {
  margin-right: 10px;
}
p {
  text-align: center;
}
span {
  font-weight: bold;
}
#buy {
  background-color: #4CAF50;
  color: white;
}
#deposit {
  background-color: #008CBA;
  color: white;
}
#refund {
  background-color: #f44336;
  color: white;
}
#green {
  color: green;
}
#red {
  color: red;
}
#orange {
  color: orange;
}
@media (min-width: 768px) {
  .product, .deposit {
      width: calc(22.22% - 20px);
  }
  .seller-input {
      width: calc(80% - 20px);
  }
}